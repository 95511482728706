
// Generated by NIFParseGen
import { mat3, vec3 } from "gl-matrix";
import { Color, White, colorNewCopy } from "../Color.js";
import ArrayBufferSlice from "../ArrayBufferSlice.js";
import { Stream, RecordRef, NiParse } from "./NIFBase.js";

export namespace NIFParse {

export const enum ApplyMode {
    APPLY_REPLACE = 0,
    APPLY_DECAL = 1,
    APPLY_MODULATE = 2,
    APPLY_HILIGHT = 3,
    APPLY_HILIGHT2 = 4,
};

export const enum KeyType {
    LINEAR_KEY = 1,
    QUADRATIC_KEY = 2,
    TBC_KEY = 3,
    XYZ_ROTATION_KEY = 4,
    CONST_KEY = 5,
};

export const enum PixelFormat {
    FMT_RGB = 0,
    FMT_RGBA = 1,
    FMT_PAL = 2,
    FMT_PALA = 3,
    FMT_DXT1 = 4,
    FMT_DXT3 = 5,
    FMT_DXT5 = 6,
    FMT_RGB24NONINT = 7,
    FMT_BUMP = 8,
    FMT_BUMPLUMA = 9,
    FMT_RENDERSPEC = 10,
    FMT_1CH = 11,
    FMT_2CH = 12,
    FMT_3CH = 13,
    FMT_4CH = 14,
    FMT_DEPTH_STENCIL = 15,
    FMT_UNKNOWN = 16,
};

export const enum PixelLayout {
    LAY_PALETTIZED_8 = 0,
    LAY_HIGH_COLOR_16 = 1,
    LAY_TRUE_COLOR_32 = 2,
    LAY_COMPRESSED = 3,
    LAY_BUMPMAP = 4,
    LAY_PALETTIZED_4 = 5,
    LAY_DEFAULT = 6,
    LAY_SINGLE_COLOR_8 = 7,
    LAY_SINGLE_COLOR_16 = 8,
    LAY_SINGLE_COLOR_32 = 9,
    LAY_DOUBLE_COLOR_32 = 10,
    LAY_DOUBLE_COLOR_64 = 11,
    LAY_FLOAT_COLOR_32 = 12,
    LAY_FLOAT_COLOR_64 = 13,
    LAY_FLOAT_COLOR_128 = 14,
    LAY_SINGLE_COLOR_4 = 15,
    LAY_DEPTH_24_X8 = 16,
};

export const enum MipMapFormat {
    MIP_FMT_NO = 0,
    MIP_FMT_YES = 1,
    MIP_FMT_DEFAULT = 2,
};

export const enum AlphaFormat {
    ALPHA_NONE = 0,
    ALPHA_BINARY = 1,
    ALPHA_SMOOTH = 2,
    ALPHA_DEFAULT = 3,
};

export const enum TexClampMode {
    CLAMP_S_CLAMP_T = 0,
    CLAMP_S_WRAP_T = 1,
    WRAP_S_CLAMP_T = 2,
    WRAP_S_WRAP_T = 3,
};

export const enum TexFilterMode {
    FILTER_NEAREST = 0,
    FILTER_BILERP = 1,
    FILTER_TRILERP = 2,
    FILTER_NEAREST_MIPNEAREST = 3,
    FILTER_NEAREST_MIPLERP = 4,
    FILTER_BILERP_MIPNEAREST = 5,
    FILTER_ANISOTROPIC = 6,
};

export const enum SourceVertexMode {
    VERT_MODE_SRC_IGNORE = 0,
    VERT_MODE_SRC_EMISSIVE = 1,
    VERT_MODE_SRC_AMB_DIF = 2,
};

export const enum LightingMode {
    LIGHT_MODE_EMISSIVE = 0,
    LIGHT_MODE_EMI_AMB_DIF = 1,
};

export const enum CycleType {
    CYCLE_LOOP = 0,
    CYCLE_REVERSE = 1,
    CYCLE_CLAMP = 2,
};

export const enum FieldType {
    FIELD_WIND = 0,
    FIELD_POINT = 1,
};

export const enum TestFunction {
    TEST_ALWAYS = 0,
    TEST_LESS = 1,
    TEST_EQUAL = 2,
    TEST_LESS_EQUAL = 3,
    TEST_GREATER = 4,
    TEST_NOT_EQUAL = 5,
    TEST_GREATER_EQUAL = 6,
    TEST_NEVER = 7,
};

export const enum AlphaFunction {
    ONE = 0,
    ZERO = 1,
    SRC_COLOR = 2,
    INV_SRC_COLOR = 3,
    DEST_COLOR = 4,
    INV_DEST_COLOR = 5,
    SRC_ALPHA = 6,
    INV_SRC_ALPHA = 7,
    DEST_ALPHA = 8,
    INV_DEST_ALPHA = 9,
    SRC_ALPHA_SATURATE = 10,
};

export const enum TextureType {
    TEX_PROJECTED_LIGHT = 0,
    TEX_PROJECTED_SHADOW = 1,
    TEX_ENVIRONMENT_MAP = 2,
    TEX_FOG_MAP = 3,
};

export const enum CoordGenType {
    CG_WORLD_PARALLEL = 0,
    CG_WORLD_PERSPECTIVE = 1,
    CG_SPHERE_MAP = 2,
    CG_SPECULAR_CUBE_MAP = 3,
    CG_DIFFUSE_CUBE_MAP = 4,
};

export const enum BoundVolumeType {
    BASE_BV = 4294967295,
    SPHERE_BV = 0,
    BOX_BV = 1,
    CAPSULE_BV = 2,
    UNION_BV = 4,
    HALFSPACE_BV = 5,
};

export const enum AnimType {
    APP_TIME = 0,
    APP_INIT = 1,
};

class TimeControllerFlags {
    public animType: AnimType;
    public cycleType: CycleType;
    public active: boolean;
    public playBackwards: boolean;
    public managerControlled: boolean;
    public computeScaledTime: boolean;
    public forcedUpdate: boolean;

    public parse(stream: Stream): void {
        const internal = stream.readUint16();
        this.animType = (internal >>> 0) & 0x00000001;
        this.cycleType = (internal >>> 1) & 0x00000003;
        this.active = !!((internal >>> 3) & 0x00000001);
        this.playBackwards = !!((internal >>> 4) & 0x00000001);
        this.managerControlled = !!((internal >>> 5) & 0x00000001);
        this.computeScaledTime = !!((internal >>> 6) & 0x00000001);
        this.forcedUpdate = !!((internal >>> 7) & 0x00000001);
    }
}

class AlphaFlags {
    public alphaBlend: boolean;
    public sourceBlendMode: AlphaFunction;
    public destinationBlendMode: AlphaFunction;
    public alphaTest: boolean;
    public testFunc: TestFunction;
    public noSorter: boolean;
    public cloneUnique: boolean;
    public editorAlphaThreshold: boolean;

    public parse(stream: Stream): void {
        const internal = stream.readUint16();
        this.alphaBlend = !!((internal >>> 0) & 0x00000001);
        this.sourceBlendMode = (internal >>> 1) & 0x0000000f;
        this.destinationBlendMode = (internal >>> 5) & 0x0000000f;
        this.alphaTest = !!((internal >>> 9) & 0x00000001);
        this.testFunc = (internal >>> 10) & 0x00000007;
        this.noSorter = !!((internal >>> 13) & 0x00000001);
        this.cloneUnique = !!((internal >>> 14) & 0x00000001);
        this.editorAlphaThreshold = !!((internal >>> 15) & 0x00000001);
    }
}

class VertexColorFlags {
    public colorMode: number;
    public lightingMode: LightingMode;
    public sourceVertexMode: SourceVertexMode;

    public parse(stream: Stream): void {
        const internal = stream.readUint16();
        this.colorMode = (internal >>> 0) & 0x00000007;
        this.lightingMode = (internal >>> 3) & 0x00000001;
        this.sourceVertexMode = (internal >>> 4) & 0x00000003;
    }
}

class ZBufferFlags {
    public zBufferTest: boolean;
    public zBufferWrite: boolean;
    public testFunc: TestFunction;

    public parse(stream: Stream): void {
        const internal = stream.readUint16();
        this.zBufferTest = !!((internal >>> 0) & 0x00000001);
        this.zBufferWrite = !!((internal >>> 1) & 0x00000001);
        this.testFunc = (internal >>> 2) & 0x00000007;
    }
}

export const enum NiNBTMethod {
    NBT_METHOD_NONE = 0,
    NBT_METHOD_NDL = 1,
    NBT_METHOD_MAX = 2,
    NBT_METHOD_ATI = 3,
};

class NiGeometryDataFlags {
    public numUVSets: number;
    public havokMaterial: number;
    public nBTMethod: NiNBTMethod;

    public parse(stream: Stream): void {
        const internal = stream.readUint16();
        this.numUVSets = (internal >>> 0) & 0x0000003f;
        this.havokMaterial = (internal >>> 6) & 0x0000003f;
        this.nBTMethod = (internal >>> 12) & 0x00000003;
    }
}

export class FilePath {
    public string: string;

    public parse(stream: Stream, arg: number | null = null): void {
        this.string = stream.readSizedString();
    }
}

export class MatchGroup {
    protected numVertices: number;
    public vertexIndices: number[] = [];

    public parse(stream: Stream, arg: number | null = null): void {
        this.numVertices = stream.readUint16();
        for (let i = 0; i < this.numVertices; i++) {
            this.vertexIndices[i] = stream.readUint16();
        }
    }
}

export class Quaternion {
    public w: number;
    public x: number;
    public y: number;
    public z: number;

    public parse(stream: Stream, arg: number | null = null): void {
        this.w = stream.readFloat32();
        this.x = stream.readFloat32();
        this.y = stream.readFloat32();
        this.z = stream.readFloat32();
    }
}

export class Matrix22 {
    public m11: number;
    public m21: number;
    public m12: number;
    public m22: number;

    public parse(stream: Stream, arg: number | null = null): void {
        this.m11 = stream.readFloat32();
        this.m21 = stream.readFloat32();
        this.m12 = stream.readFloat32();
        this.m22 = stream.readFloat32();
    }
}

export class BoneVertData {
    public index: number;
    public weight: number;

    public parse(stream: Stream, arg: number | null = null): void {
        this.index = stream.readUint16();
        this.weight = stream.readFloat32();
    }
}

export class TBC {
    public t: number;
    public b: number;
    public c: number;

    public parse(stream: Stream, arg: number | null = null): void {
        this.t = stream.readFloat32();
        this.b = stream.readFloat32();
        this.c = stream.readFloat32();
    }
}

export class TexCoord {
    public u: number;
    public v: number;

    public parse(stream: Stream, arg: number | null = null): void {
        this.u = stream.readFloat32();
        this.v = stream.readFloat32();
    }
}

export class TexDesc {
    public source: RecordRef<NiSourceTexture> = new RecordRef<NiSourceTexture>();
    public clampMode: TexClampMode;
    public filterMode: TexFilterMode;
    public uVSet: number;
    public pS2L: number;
    public pS2K: number;
    public unknownShort1: number;

    public parse(stream: Stream, arg: number | null = null): void {
        this.source.parse(stream);
        this.clampMode = stream.readUint32();
        this.filterMode = stream.readUint32();
        this.uVSet = stream.readUint32();
        this.pS2L = stream.readInt16();
        this.pS2K = stream.readInt16();
        this.unknownShort1 = stream.readUint16();
    }
}

export class Triangle {
    public v1: number;
    public v2: number;
    public v3: number;

    public parse(stream: Stream, arg: number | null = null): void {
        this.v1 = stream.readUint16();
        this.v2 = stream.readUint16();
        this.v3 = stream.readUint16();
    }
}

export class SkinPartition {
    protected numVertices: number;
    protected numTriangles: number;
    protected numBones: number;
    protected numStrips: number;
    protected numWeightsPerVertex: number;
    public bones: number[] = [];
    public vertexMap: number[] = [];
    public vertexWeights: number[][] = [];
    protected stripLengths: number[] = [];
    public strips: number[][] | null = null;
    public triangles: Triangle[] | null = null;
    public hasBoneIndices: boolean;
    public boneIndices: number[][] | null = null;

    public parse(stream: Stream, arg: number | null = null): void {
        this.numVertices = stream.readUint16();
        this.numTriangles = stream.readUint16();
        this.numBones = stream.readUint16();
        this.numStrips = stream.readUint16();
        this.numWeightsPerVertex = stream.readUint16();
        for (let i = 0; i < this.numBones; i++) {
            this.bones[i] = stream.readUint16();
        }
        for (let i = 0; i < this.numVertices; i++) {
            this.vertexMap[i] = stream.readUint16();
        }
        for (let i = 0; i < this.numVertices; i++) {
            this.vertexWeights[i] = [];
            for (let j = 0; j < this.numWeightsPerVertex; j++) {
                this.vertexWeights[i][j] = stream.readFloat32();
            }
        }
        for (let i = 0; i < this.numStrips; i++) {
            this.stripLengths[i] = stream.readUint16();
        }
        if ((this.numStrips !== 0)) {
            this.strips = [];
            for (let i = 0; i < this.numStrips; i++) {
                this.strips[i] = [];
                for (let j = 0; j < this.stripLengths[i]; j++) {
                    this.strips[i][j] = stream.readUint16();
                }
            }
        }
        if ((this.numStrips === 0)) {
            this.triangles = [];
            for (let i = 0; i < this.numTriangles; i++) {
                this.triangles[i] = new Triangle();
                this.triangles[i].parse(stream);
            }
        }
        this.hasBoneIndices = stream.readBool();
        if (this.hasBoneIndices) {
            this.boneIndices = [];
            for (let i = 0; i < this.numVertices; i++) {
                this.boneIndices[i] = [];
                for (let j = 0; j < this.numWeightsPerVertex; j++) {
                    this.boneIndices[i][j] = stream.readUint8();
                }
            }
        }
    }
}

export class NiPlane {
    public normal: vec3 = vec3.create();
    public constant: number;

    public parse(stream: Stream, arg: number | null = null): void {
        stream.readVector3(this.normal);
        this.constant = stream.readFloat32();
    }
}

export class NiBound {
    public center: vec3 = vec3.create();
    public radius: number;

    public parse(stream: Stream, arg: number | null = null): void {
        stream.readVector3(this.center);
        this.radius = stream.readFloat32();
    }
}

export class NiTransform {
    public rotation: mat3 = mat3.create();
    public translation: vec3 = vec3.create();
    public scale: number;

    public parse(stream: Stream, arg: number | null = null): void {
        stream.readMatrix33(this.rotation);
        stream.readVector3(this.translation);
        this.scale = stream.readFloat32();
    }
}

export class NiParticleInfo {
    public velocity: vec3 = vec3.create();
    public rotationAxis: vec3 = vec3.create();
    public age: number;
    public lifeSpan: number;
    public lastUpdate: number;
    public spawnGeneration: number;
    public code: number;

    public parse(stream: Stream, arg: number | null = null): void {
        stream.readVector3(this.velocity);
        stream.readVector3(this.rotationAxis);
        this.age = stream.readFloat32();
        this.lifeSpan = stream.readFloat32();
        this.lastUpdate = stream.readFloat32();
        this.spawnGeneration = stream.readUint16();
        this.code = stream.readUint16();
    }
}

export class BoneData {
    public skinTransform: NiTransform = new NiTransform();
    public boundingSphere: NiBound = new NiBound();
    protected numVertices: number;
    public vertexWeights: BoneVertData[] = [];

    public parse(stream: Stream, arg: number | null = null): void {
        this.skinTransform.parse(stream);
        this.boundingSphere.parse(stream);
        this.numVertices = stream.readUint16();
        for (let i = 0; i < this.numVertices; i++) {
            this.vertexWeights[i] = new BoneVertData();
            this.vertexWeights[i].parse(stream);
        }
    }
}

export class BoxBV {
    public center: vec3 = vec3.create();
    public axis: vec3[] = [];
    public extent: vec3 = vec3.create();

    public parse(stream: Stream, arg: number | null = null): void {
        stream.readVector3(this.center);
        for (let i = 0; i < 3; i++) {
            this.axis[i] = vec3.create();
            stream.readVector3(this.axis[i]);
        }
        stream.readVector3(this.extent);
    }
}

export class CapsuleBV {
    public center: vec3 = vec3.create();
    public origin: vec3 = vec3.create();
    public extent: number;
    public radius: number;

    public parse(stream: Stream, arg: number | null = null): void {
        stream.readVector3(this.center);
        stream.readVector3(this.origin);
        this.extent = stream.readFloat32();
        this.radius = stream.readFloat32();
    }
}

export class HalfSpaceBV {
    public plane: NiPlane = new NiPlane();
    public center: vec3 = vec3.create();

    public parse(stream: Stream, arg: number | null = null): void {
        this.plane.parse(stream);
        stream.readVector3(this.center);
    }
}

export class BoundingVolume {
    public collisionType: BoundVolumeType;
    public sphere: NiBound | null = null;
    public box: BoxBV | null = null;
    public capsule: CapsuleBV | null = null;
    public unionBV: UnionBV | null = null;
    public halfSpace: HalfSpaceBV | null = null;

    public parse(stream: Stream, arg: number | null = null): void {
        this.collisionType = stream.readUint32();
        if ((this.collisionType === 0)) {
            this.sphere = new NiBound();
            this.sphere.parse(stream);
        }
        if ((this.collisionType === 1)) {
            this.box = new BoxBV();
            this.box.parse(stream);
        }
        if ((this.collisionType === 2)) {
            this.capsule = new CapsuleBV();
            this.capsule.parse(stream);
        }
        if ((this.collisionType === 4)) {
            this.unionBV = new UnionBV();
            this.unionBV.parse(stream);
        }
        if ((this.collisionType === 5)) {
            this.halfSpace = new HalfSpaceBV();
            this.halfSpace.parse(stream);
        }
    }
}

export class UnionBV {
    protected numBV: number;
    public boundingVolumes: BoundingVolume[] = [];

    public parse(stream: Stream, arg: number | null = null): void {
        this.numBV = stream.readUint32();
        for (let i = 0; i < this.numBV; i++) {
            this.boundingVolumes[i] = new BoundingVolume();
            this.boundingVolumes[i].parse(stream);
        }
    }
}

export class NiObject {
    public parse(stream: Stream, arg: number | null = null): void {
    }
}

export class NiParticleModifier extends NiObject {
    public nextModifier: RecordRef<NiParticleModifier> = new RecordRef<NiParticleModifier>();
    public controller: RecordRef<NiParticleSystemController> = new RecordRef<NiParticleSystemController>();

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.nextModifier.parse(stream);
        this.controller.parse(stream);
    }
}

export class NiExtraData extends NiObject {
    public nextExtraData: RecordRef<NiExtraData> = new RecordRef<NiExtraData>();
    public numBytes: number;

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.nextExtraData.parse(stream);
        this.numBytes = stream.readUint32();
    }
}

export class NiObjectNET extends NiObject {
    public name: string;
    public extraData: RecordRef<NiExtraData> = new RecordRef<NiExtraData>();
    public controller: RecordRef<NiTimeController> = new RecordRef<NiTimeController>();

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.name = stream.readString();
        this.extraData.parse(stream);
        this.controller.parse(stream);
    }
}

export class NiAVObject extends NiObjectNET {
    public flags: number;
    public translation: vec3 = vec3.create();
    public rotation: mat3 = mat3.create();
    public scale: number;
    public velocity: vec3 = vec3.create();
    protected numProperties: number;
    public properties: RecordRef<NiProperty>[] = [];
    public hasBoundingVolume: boolean;
    public boundingVolume: BoundingVolume | null = null;

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.flags = stream.readUint16();
        stream.readVector3(this.translation);
        stream.readMatrix33(this.rotation);
        this.scale = stream.readFloat32();
        stream.readVector3(this.velocity);
        this.numProperties = stream.readUint32();
        for (let i = 0; i < this.numProperties; i++) {
            this.properties[i] = new RecordRef<NiProperty>();
            this.properties[i].parse(stream);
        }
        this.hasBoundingVolume = stream.readBool();
        if (this.hasBoundingVolume) {
            this.boundingVolume = new BoundingVolume();
            this.boundingVolume.parse(stream);
        }
    }
}

export class NiDynamicEffect extends NiAVObject {
    protected numAffectedNodes: number;
    public affectedNodePointers: number[] = [];

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.numAffectedNodes = stream.readUint32();
        for (let i = 0; i < this.numAffectedNodes; i++) {
            this.affectedNodePointers[i] = stream.readUint32();
        }
    }
}

export class NiProperty extends NiObjectNET {
    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
    }
}

export class NiTimeController extends NiObject {
    public nextController: RecordRef<NiTimeController> = new RecordRef<NiTimeController>();
    public flags: TimeControllerFlags = new TimeControllerFlags();
    public frequency: number;
    public phase: number;
    public startTime: number;
    public stopTime: number;
    public target: RecordRef<NiObjectNET> = new RecordRef<NiObjectNET>();

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.nextController.parse(stream);
        this.flags.parse(stream);
        this.frequency = stream.readFloat32();
        this.phase = stream.readFloat32();
        this.startTime = stream.readFloat32();
        this.stopTime = stream.readFloat32();
        this.target.parse(stream);
    }
}

export class NiInterpController extends NiTimeController {
    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
    }
}

export class NiSingleInterpController extends NiInterpController {
    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
    }
}

export class NiKeyframeController extends NiSingleInterpController {
    public data: RecordRef<NiKeyframeData> = new RecordRef<NiKeyframeData>();

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.data.parse(stream);
    }
}

export class NiFloatInterpController extends NiSingleInterpController {
    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
    }
}

export class NiAlphaController extends NiFloatInterpController {
    public data: RecordRef<NiFloatData> = new RecordRef<NiFloatData>();

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.data.parse(stream);
    }
}

export class NiGeometry extends NiAVObject {
    public data: RecordRef<NiGeometryData> = new RecordRef<NiGeometryData>();
    public skinInstance: RecordRef<NiSkinInstance> = new RecordRef<NiSkinInstance>();

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.data.parse(stream);
        this.skinInstance.parse(stream);
    }
}

export class NiTriBasedGeom extends NiGeometry {
    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
    }
}


class NiGeometryData extends NiObject {
    public hasVertices: boolean;
    protected numVertices: number;
    public vertices: ArrayBufferSlice | null = null;
    public hasNormals: boolean;
    public normals: ArrayBufferSlice | null = null;
    public boundingSphere: NiBound = new NiBound();
    public hasVertexColors: boolean;
    public vertexColors: ArrayBufferSlice | null = null;
    public dataFlags: NiGeometryDataFlags = new NiGeometryDataFlags();
    public hasUV: boolean;
    public uVSets: ArrayBufferSlice[] = [];

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.numVertices = stream.readUint16();
        this.hasVertices = stream.readBool();
        if (this.hasVertices) {
            this.vertices = stream.readBytes(this.numVertices * 0x0C);
        }
        this.hasNormals = stream.readBool();
        if (this.hasNormals) {
            this.normals = stream.readBytes(this.numVertices * 0x0C);
        }
        this.boundingSphere.parse(stream);
        this.hasVertexColors = stream.readBool();
        if (this.hasVertexColors) {
            this.vertexColors = stream.readBytes(this.numVertices * 0x10);
        }
        this.dataFlags.parse(stream);
        this.hasUV = stream.readBool();
        for (let i = 0; i < this.dataFlags.numUVSets; i++) {
            this.uVSets[i] = stream.readBytes(this.numVertices * 0x08);
        }
    }
}

export class NiTriBasedGeomData extends NiGeometryData {
    protected numTriangles: number;

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.numTriangles = stream.readUint16();
    }
}

export class NiAlphaProperty extends NiProperty {
    public flags: AlphaFlags = new AlphaFlags();
    public threshold: number;

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.flags.parse(stream);
        this.threshold = stream.readUint8();
    }
}

export class NiParticlesData extends NiGeometryData {
    public numParticles: number;
    public particleRadius: number;
    public numActive: number;
    public hasSizes: boolean;
    public sizes: number[] | null = null;

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.numParticles = stream.readUint16();
        this.particleRadius = stream.readFloat32();
        this.numActive = stream.readUint16();
        this.hasSizes = stream.readBool();
        if (this.hasSizes) {
            this.sizes = [];
            for (let i = 0; i < this.numVertices; i++) {
                this.sizes[i] = stream.readFloat32();
            }
        }
    }
}

export class NiRotatingParticlesData extends NiParticlesData {
    public hasRotations2: boolean;
    public rotations2: Quaternion[] | null = null;

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.hasRotations2 = stream.readBool();
        if (this.hasRotations2) {
            this.rotations2 = [];
            for (let i = 0; i < this.numVertices; i++) {
                this.rotations2[i] = new Quaternion();
                this.rotations2[i].parse(stream);
            }
        }
    }
}

export class NiAutoNormalParticlesData extends NiParticlesData {
    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
    }
}

export class NiColorData extends NiObject {
    public data: KeyGroup$Color = new KeyGroup$Color();

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.data.parse(stream);
    }
}

export class NiFloatData extends NiObject {
    public data: KeyGroup$number = new KeyGroup$number();

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.data.parse(stream);
    }
}

export class NiGravity extends NiParticleModifier {
    public decay: number;
    public force: number;
    public type: FieldType;
    public position: vec3 = vec3.create();
    public direction: vec3 = vec3.create();

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.decay = stream.readFloat32();
        this.force = stream.readFloat32();
        this.type = stream.readUint32();
        stream.readVector3(this.position);
        stream.readVector3(this.direction);
    }
}

export class NiKeyframeData extends NiObject {
    protected numRotationKeys: number;
    public rotationType: KeyType | null = null;
    public quaternionKeys: QuatKey$Quaternion[] | null = null;
    public order: number | null = null;
    public xYZRotations: KeyGroup$number[] | null = null;
    public translations: KeyGroup$vec3 = new KeyGroup$vec3();
    public scales: KeyGroup$number = new KeyGroup$number();

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.numRotationKeys = stream.readUint32();
        if ((this.numRotationKeys !== 0)) {
            this.rotationType = stream.readUint32();
        }
        if ((this.rotationType !== 4)) {
            this.quaternionKeys = [];
            for (let i = 0; i < this.numRotationKeys; i++) {
                this.quaternionKeys[i] = new QuatKey$Quaternion();
                this.quaternionKeys[i].parse(stream, this.rotationType);
            }
        }
        if ((this.rotationType === 4)) {
            this.order = stream.readFloat32();
        }
        if ((this.rotationType === 4)) {
            this.xYZRotations = [];
            for (let i = 0; i < 3; i++) {
                this.xYZRotations[i] = new KeyGroup$number();
                this.xYZRotations[i].parse(stream);
            }
        }
        this.translations.parse(stream);
        this.scales.parse(stream);
    }
}

export class NiMaterialProperty extends NiProperty {
    public flags: number;
    public ambientColor: Color = colorNewCopy(White);
    public diffuseColor: Color = colorNewCopy(White);
    public specularColor: Color = colorNewCopy(White);
    public emissiveColor: Color = colorNewCopy(White);
    public glossiness: number;
    public alpha: number;

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.flags = stream.readUint16();
        stream.readColor3(this.ambientColor);
        stream.readColor3(this.diffuseColor);
        stream.readColor3(this.specularColor);
        stream.readColor3(this.emissiveColor);
        this.glossiness = stream.readFloat32();
        this.alpha = stream.readFloat32();
    }
}

export class NiNode extends NiAVObject {
    protected numChildren: number;
    public children: RecordRef<NiAVObject>[] = [];
    protected numEffects: number;
    public effects: RecordRef<NiDynamicEffect>[] = [];

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.numChildren = stream.readUint32();
        for (let i = 0; i < this.numChildren; i++) {
            this.children[i] = new RecordRef<NiAVObject>();
            this.children[i].parse(stream);
        }
        this.numEffects = stream.readUint32();
        for (let i = 0; i < this.numEffects; i++) {
            this.effects[i] = new RecordRef<NiDynamicEffect>();
            this.effects[i].parse(stream);
        }
    }
}

export class AvoidNode extends NiNode {
    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
    }
}

export class NiBSAnimationNode extends NiNode {
    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
    }
}

export class NiBSParticleNode extends NiNode {
    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
    }
}

export class NiParticleColorModifier extends NiParticleModifier {
    public colorData: RecordRef<NiColorData> = new RecordRef<NiColorData>();

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.colorData.parse(stream);
    }
}

export class NiParticleGrowFade extends NiParticleModifier {
    public grow: number;
    public fade: number;

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.grow = stream.readFloat32();
        this.fade = stream.readFloat32();
    }
}

export class NiParticles extends NiGeometry {
    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
    }
}

export class NiAutoNormalParticles extends NiParticles {
    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
    }
}

export class NiEmitterModifier extends NiObject {
    public nextModifier: RecordRef<NiEmitterModifier> = new RecordRef<NiEmitterModifier>();
    public controller: RecordRef<NiParticleSystemController> = new RecordRef<NiParticleSystemController>();

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.nextModifier.parse(stream);
        this.controller.parse(stream);
    }
}

export class NiParticleSystemController extends NiTimeController {
    public speed: number;
    public speedVariation: number;
    public declination: number;
    public declinationVariation: number;
    public planarAngle: number;
    public planarAngleVariation: number;
    public initialNormal: vec3 = vec3.create();
    public initialColor: Color = colorNewCopy(White);
    public initialSize: number;
    public emitStartTime: number;
    public emitStopTime: number;
    public resetParticleSystem: number;
    public birthRate: number;
    public lifetime: number;
    public lifetimeVariation: number;
    public useBirthRate: number;
    public spawnOnDeath: number;
    public emitterDimensions: vec3 = vec3.create();
    public emitter: RecordRef<NiAVObject> = new RecordRef<NiAVObject>();
    public numSpawnGenerations: number;
    public percentageSpawned: number;
    public spawnMultiplier: number;
    public spawnSpeedChaos: number;
    public spawnDirChaos: number;
    protected numParticles: number;
    public numValid: number;
    public particles: NiParticleInfo[] = [];
    public emitterModifier: RecordRef<NiEmitterModifier> = new RecordRef<NiEmitterModifier>();
    public particleModifier: RecordRef<NiParticleModifier> = new RecordRef<NiParticleModifier>();
    public particleCollider: RecordRef<NiParticleCollider> = new RecordRef<NiParticleCollider>();
    public staticTargetBound: number;

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.speed = stream.readFloat32();
        this.speedVariation = stream.readFloat32();
        this.declination = stream.readFloat32();
        this.declinationVariation = stream.readFloat32();
        this.planarAngle = stream.readFloat32();
        this.planarAngleVariation = stream.readFloat32();
        stream.readVector3(this.initialNormal);
        stream.readColor4(this.initialColor);
        this.initialSize = stream.readFloat32();
        this.emitStartTime = stream.readFloat32();
        this.emitStopTime = stream.readFloat32();
        this.resetParticleSystem = stream.readUint8();
        this.birthRate = stream.readFloat32();
        this.lifetime = stream.readFloat32();
        this.lifetimeVariation = stream.readFloat32();
        this.useBirthRate = stream.readUint8();
        this.spawnOnDeath = stream.readUint8();
        stream.readVector3(this.emitterDimensions);
        this.emitter.parse(stream);
        this.numSpawnGenerations = stream.readUint16();
        this.percentageSpawned = stream.readFloat32();
        this.spawnMultiplier = stream.readUint16();
        this.spawnSpeedChaos = stream.readFloat32();
        this.spawnDirChaos = stream.readFloat32();
        this.numParticles = stream.readUint16();
        this.numValid = stream.readUint16();
        for (let i = 0; i < this.numParticles; i++) {
            this.particles[i] = new NiParticleInfo();
            this.particles[i].parse(stream);
        }
        this.emitterModifier.parse(stream);
        this.particleModifier.parse(stream);
        this.particleCollider.parse(stream);
        this.staticTargetBound = stream.readUint8();
    }
}

export class NiPixelFormat extends NiObject {
    public pixelFormat: PixelFormat;
    public redMask: number;
    public greenMask: number;
    public blueMask: number;
    public alphaMask: number;
    public bitsPerPixel: number;
    public oldFastCompare: number[] = [];

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.pixelFormat = stream.readUint32();
        this.redMask = stream.readUint32();
        this.greenMask = stream.readUint32();
        this.blueMask = stream.readUint32();
        this.alphaMask = stream.readUint32();
        this.bitsPerPixel = stream.readUint32();
        for (let i = 0; i < 8; i++) {
            this.oldFastCompare[i] = stream.readUint8();
        }
    }
}

export class NiParticleCollider extends NiParticleModifier {
    public bounce: number;

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.bounce = stream.readFloat32();
    }
}

export class NiRotatingParticles extends NiParticles {
    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
    }
}

export class NiSkinData extends NiObject {
    public skinTransform: NiTransform = new NiTransform();
    protected numBones: number;
    public skinPartition: RecordRef<NiSkinPartition> = new RecordRef<NiSkinPartition>();
    public boneList: BoneData[] = [];

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.skinTransform.parse(stream);
        this.numBones = stream.readUint32();
        this.skinPartition.parse(stream);
        for (let i = 0; i < this.numBones; i++) {
            this.boneList[i] = new BoneData();
            this.boneList[i].parse(stream, 1);
        }
    }
}

export class NiSkinInstance extends NiObject {
    public data: RecordRef<NiSkinData> = new RecordRef<NiSkinData>();
    public skeletonRoot: RecordRef<NiNode> = new RecordRef<NiNode>();
    protected numBones: number;
    public bones: RecordRef<NiNode>[] = [];

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.data.parse(stream);
        this.skeletonRoot.parse(stream);
        this.numBones = stream.readUint32();
        for (let i = 0; i < this.numBones; i++) {
            this.bones[i] = new RecordRef<NiNode>();
            this.bones[i].parse(stream);
        }
    }
}

export class NiSkinPartition extends NiObject {
    protected numPartitions: number;
    public partitions: SkinPartition[] = [];

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.numPartitions = stream.readUint32();
        for (let i = 0; i < this.numPartitions; i++) {
            this.partitions[i] = new SkinPartition();
            this.partitions[i].parse(stream);
        }
    }
}

export class NiTexture extends NiObjectNET {
    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
    }
}

export class FormatPrefs {
    public pixelLayout: PixelLayout;
    public useMipmaps: MipMapFormat;
    public alphaFormat: AlphaFormat;

    public parse(stream: Stream, arg: number | null = null): void {
        this.pixelLayout = stream.readUint32();
        this.useMipmaps = stream.readUint32();
        this.alphaFormat = stream.readUint32();
    }
}

export class NiSourceTexture extends NiTexture {
    public useExternal: number;
    public useInternal: number | null = null;
    public fileName: FilePath | null = null;
    public pixelData: RecordRef<NiPixelFormat> | null = null;
    public formatPrefs: FormatPrefs = new FormatPrefs();
    public isStatic: number;

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.useExternal = stream.readUint8();
        if ((this.useExternal === 0)) {
            this.useInternal = stream.readUint8();
        }
        if ((this.useExternal === 1)) {
            this.fileName = new FilePath();
            this.fileName.parse(stream);
        }
        if (((this.useExternal === 0) && (this.useInternal === 1))) {
            this.pixelData = new RecordRef<NiPixelFormat>();
            this.pixelData.parse(stream);
        }
        this.formatPrefs.parse(stream);
        this.isStatic = stream.readUint8();
    }
}

export class NiStringExtraData extends NiExtraData {
    public stringData: string;

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.stringData = stream.readString();
    }
}

export class NiTextKeyExtraData extends NiExtraData {
    protected numTextKeys: number;
    public textKeys: Key$string[] = [];

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.numTextKeys = stream.readUint32();
        for (let i = 0; i < this.numTextKeys; i++) {
            this.textKeys[i] = new Key$string();
            this.textKeys[i].parse(stream, 1);
        }
    }
}

export class NiTextureEffect extends NiDynamicEffect {
    public modelProjectionMatrix: mat3 = mat3.create();
    public modelProjectionTranslation: vec3 = vec3.create();
    public textureFiltering: TexFilterMode;
    public textureClamping: TexClampMode;
    public textureType: TextureType;
    public coordinateGenerationType: CoordGenType;
    public sourceTexture: RecordRef<NiSourceTexture> = new RecordRef<NiSourceTexture>();
    public enablePlane: number;
    public plane: NiPlane = new NiPlane();
    public pS2L: number;
    public pS2K: number;
    public unknownShort: number;

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        stream.readMatrix33(this.modelProjectionMatrix);
        stream.readVector3(this.modelProjectionTranslation);
        this.textureFiltering = stream.readUint32();
        this.textureClamping = stream.readUint32();
        this.textureType = stream.readUint32();
        this.coordinateGenerationType = stream.readUint32();
        this.sourceTexture.parse(stream);
        this.enablePlane = stream.readUint8();
        this.plane.parse(stream);
        this.pS2L = stream.readInt16();
        this.pS2K = stream.readInt16();
        this.unknownShort = stream.readUint16();
    }
}

export class NiTexturingProperty extends NiProperty {
    public flags: number;
    public applyMode: ApplyMode;
    public textureCount: number;
    public hasBaseTexture: boolean;
    public baseTexture: TexDesc | null = null;
    public hasDarkTexture: boolean;
    public darkTexture: TexDesc | null = null;
    public hasDetailTexture: boolean;
    public detailTexture: TexDesc | null = null;
    public hasGlossTexture: boolean;
    public glossTexture: TexDesc | null = null;
    public hasGlowTexture: boolean;
    public glowTexture: TexDesc | null = null;
    public hasBumpMapTexture: boolean | null = null;
    public bumpMapTexture: TexDesc | null = null;
    public bumpMapLumaScale: number | null = null;
    public bumpMapLumaOffset: number | null = null;
    public bumpMapMatrix: Matrix22 | null = null;
    public hasDecal0Texture: boolean | null = null;
    public decal0Texture: TexDesc | null = null;
    public hasDecal1Texture: boolean | null = null;
    public decal1Texture: TexDesc | null = null;
    public hasDecal2Texture: boolean | null = null;
    public decal2Texture: TexDesc | null = null;
    public hasDecal3Texture: boolean | null = null;
    public decal3Texture: TexDesc | null = null;

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.flags = stream.readUint16();
        this.applyMode = stream.readUint32();
        this.textureCount = stream.readUint32();
        this.hasBaseTexture = stream.readBool();
        if (this.hasBaseTexture) {
            this.baseTexture = new TexDesc();
            this.baseTexture.parse(stream);
        }
        this.hasDarkTexture = stream.readBool();
        if (this.hasDarkTexture) {
            this.darkTexture = new TexDesc();
            this.darkTexture.parse(stream);
        }
        this.hasDetailTexture = stream.readBool();
        if (this.hasDetailTexture) {
            this.detailTexture = new TexDesc();
            this.detailTexture.parse(stream);
        }
        this.hasGlossTexture = stream.readBool();
        if (this.hasGlossTexture) {
            this.glossTexture = new TexDesc();
            this.glossTexture.parse(stream);
        }
        this.hasGlowTexture = stream.readBool();
        if (this.hasGlowTexture) {
            this.glowTexture = new TexDesc();
            this.glowTexture.parse(stream);
        }
        if ((this.textureCount > 5)) {
            this.hasBumpMapTexture = stream.readBool();
        }
        if (this.hasBumpMapTexture) {
            this.bumpMapTexture = new TexDesc();
            this.bumpMapTexture.parse(stream);
        }
        if (this.hasBumpMapTexture) {
            this.bumpMapLumaScale = stream.readFloat32();
        }
        if (this.hasBumpMapTexture) {
            this.bumpMapLumaOffset = stream.readFloat32();
        }
        if (this.hasBumpMapTexture) {
            this.bumpMapMatrix = new Matrix22();
            this.bumpMapMatrix.parse(stream);
        }
        if ((this.textureCount > 6)) {
            this.hasDecal0Texture = stream.readBool();
        }
        if (this.hasDecal0Texture) {
            this.decal0Texture = new TexDesc();
            this.decal0Texture.parse(stream);
        }
        if ((this.textureCount > 7)) {
            this.hasDecal1Texture = stream.readBool();
        }
        if (this.hasDecal1Texture) {
            this.decal1Texture = new TexDesc();
            this.decal1Texture.parse(stream);
        }
        if ((this.textureCount > 8)) {
            this.hasDecal2Texture = stream.readBool();
        }
        if (this.hasDecal2Texture) {
            this.decal2Texture = new TexDesc();
            this.decal2Texture.parse(stream);
        }
        if ((this.textureCount > 9)) {
            this.hasDecal3Texture = stream.readBool();
        }
        if (this.hasDecal3Texture) {
            this.decal3Texture = new TexDesc();
            this.decal3Texture.parse(stream);
        }
    }
}

export class NiTriShape extends NiTriBasedGeom {
    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
    }
}

export class NiTriShapeData extends NiTriBasedGeomData {
    public numTrianglePoints: number;
    public triangles: ArrayBufferSlice;
    protected numMatchGroups: number;
    public matchGroups: MatchGroup[] = [];

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.numTrianglePoints = stream.readUint32();
        this.triangles = stream.readBytes(this.numTriangles * 6);
        this.numMatchGroups = stream.readUint16();
        for (let i = 0; i < this.numMatchGroups; i++) {
            this.matchGroups[i] = new MatchGroup();
            this.matchGroups[i].parse(stream);
        }
    }
}

export class NiUVController extends NiTimeController {
    public textureSet: number;
    public data: RecordRef<NiUVData> = new RecordRef<NiUVData>();

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.textureSet = stream.readUint16();
        this.data.parse(stream);
    }
}

export class NiUVData extends NiObject {
    public uVGroups: KeyGroup$number[] = [];

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        for (let i = 0; i < 4; i++) {
            this.uVGroups[i] = new KeyGroup$number();
            this.uVGroups[i].parse(stream);
        }
    }
}

export class NiVertexColorProperty extends NiProperty {
    public flags: VertexColorFlags = new VertexColorFlags();
    public vertexMode: SourceVertexMode;
    public lightingMode: LightingMode;

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.flags.parse(stream);
        this.vertexMode = stream.readUint32();
        this.lightingMode = stream.readUint32();
    }
}

export class NiZBufferProperty extends NiProperty {
    public flags: ZBufferFlags = new ZBufferFlags();

    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
        this.flags.parse(stream);
    }
}

export class RootCollisionNode extends NiNode {
    public override parse(stream: Stream, arg: number | null = null): void {
        super.parse(stream, arg);
    }
}

export class Key$number {
    public time: number;
    public value: number;
    public forward: number | null = null;
    public backward: number | null = null;
    public tBC: TBC | null = null;

    public parse(stream: Stream, arg: number | null = null): void {
        this.time = stream.readFloat32();
        this.value = stream.readFloat32();
        if ((arg === 2)) {
            this.forward = stream.readFloat32();
        }
        if ((arg === 2)) {
            this.backward = stream.readFloat32();
        }
        if ((arg === 3)) {
            this.tBC = new TBC();
            this.tBC.parse(stream);
        }
    }
}

export class KeyGroup$number {
    protected numKeys: number;
    public interpolation: KeyType | null = null;
    public keys: Key$number[] = [];

    public parse(stream: Stream, arg: number | null = null): void {
        this.numKeys = stream.readUint32();
        if ((this.numKeys !== 0)) {
            this.interpolation = stream.readUint32();
        }
        for (let i = 0; i < this.numKeys; i++) {
            this.keys[i] = new Key$number();
            this.keys[i].parse(stream, this.interpolation);
        }
    }
}

export class KeyGroup$Color {
    protected numKeys: number;
    public interpolation: KeyType | null = null;
    public keys: Key$Color[] = [];

    public parse(stream: Stream, arg: number | null = null): void {
        this.numKeys = stream.readUint32();
        if ((this.numKeys !== 0)) {
            this.interpolation = stream.readUint32();
        }
        for (let i = 0; i < this.numKeys; i++) {
            this.keys[i] = new Key$Color();
            this.keys[i].parse(stream, this.interpolation);
        }
    }
}

export class QuatKey$Quaternion {
    public time: number;
    public value: Quaternion | null = null;
    public tBC: TBC | null = null;

    public parse(stream: Stream, arg: number | null = null): void {
        this.time = stream.readFloat32();
        if ((arg !== 4)) {
            this.value = new Quaternion();
            this.value.parse(stream);
        }
        if ((arg === 3)) {
            this.tBC = new TBC();
            this.tBC.parse(stream);
        }
    }
}

export class KeyGroup$vec3 {
    protected numKeys: number;
    public interpolation: KeyType | null = null;
    public keys: Key$vec3[] = [];

    public parse(stream: Stream, arg: number | null = null): void {
        this.numKeys = stream.readUint32();
        if ((this.numKeys !== 0)) {
            this.interpolation = stream.readUint32();
        }
        for (let i = 0; i < this.numKeys; i++) {
            this.keys[i] = new Key$vec3();
            this.keys[i].parse(stream, this.interpolation);
        }
    }
}

export class Key$string {
    public time: number;
    public value: string;
    public forward: string | null = null;
    public backward: string | null = null;
    public tBC: TBC | null = null;

    public parse(stream: Stream, arg: number | null = null): void {
        this.time = stream.readFloat32();
        this.value = stream.readString();
        if ((arg === 2)) {
            this.forward = stream.readString();
        }
        if ((arg === 2)) {
            this.backward = stream.readString();
        }
        if ((arg === 3)) {
            this.tBC = new TBC();
            this.tBC.parse(stream);
        }
    }
}

export class Key$Color {
    public time: number;
    public value: Color = colorNewCopy(White);
    public forward: Color | null = null;
    public backward: Color | null = null;
    public tBC: TBC | null = null;

    public parse(stream: Stream, arg: number | null = null): void {
        this.time = stream.readFloat32();
        stream.readColor4(this.value);
        if ((arg === 2)) {
            this.forward = colorNewCopy(White);
            stream.readColor4(this.forward);
        }
        if ((arg === 2)) {
            this.backward = colorNewCopy(White);
            stream.readColor4(this.backward);
        }
        if ((arg === 3)) {
            this.tBC = new TBC();
            this.tBC.parse(stream);
        }
    }
}

export class Key$vec3 {
    public time: number;
    public value: vec3 = vec3.create();
    public forward: vec3 | null = null;
    public backward: vec3 | null = null;
    public tBC: TBC | null = null;

    public parse(stream: Stream, arg: number | null = null): void {
        this.time = stream.readFloat32();
        stream.readVector3(this.value);
        if ((arg === 2)) {
            this.forward = vec3.create();
            stream.readVector3(this.forward);
        }
        if ((arg === 2)) {
            this.backward = vec3.create();
            stream.readVector3(this.backward);
        }
        if ((arg === 3)) {
            this.tBC = new TBC();
            this.tBC.parse(stream);
        }
    }
}

export function newRecord(recordType: string): NiParse {
    switch (recordType) {
        case 'NiObject': return new NiObject();
        case 'NiParticleModifier': return new NiParticleModifier();
        case 'NiExtraData': return new NiExtraData();
        case 'NiObjectNET': return new NiObjectNET();
        case 'NiAVObject': return new NiAVObject();
        case 'NiDynamicEffect': return new NiDynamicEffect();
        case 'NiProperty': return new NiProperty();
        case 'NiTimeController': return new NiTimeController();
        case 'NiInterpController': return new NiInterpController();
        case 'NiSingleInterpController': return new NiSingleInterpController();
        case 'NiKeyframeController': return new NiKeyframeController();
        case 'NiFloatInterpController': return new NiFloatInterpController();
        case 'NiAlphaController': return new NiAlphaController();
        case 'NiGeometry': return new NiGeometry();
        case 'NiTriBasedGeom': return new NiTriBasedGeom();
        case 'NiGeometryData': return new NiGeometryData();
        case 'NiTriBasedGeomData': return new NiTriBasedGeomData();
        case 'NiAlphaProperty': return new NiAlphaProperty();
        case 'NiParticlesData': return new NiParticlesData();
        case 'NiRotatingParticlesData': return new NiRotatingParticlesData();
        case 'NiAutoNormalParticlesData': return new NiAutoNormalParticlesData();
        case 'NiColorData': return new NiColorData();
        case 'NiFloatData': return new NiFloatData();
        case 'NiGravity': return new NiGravity();
        case 'NiKeyframeData': return new NiKeyframeData();
        case 'NiMaterialProperty': return new NiMaterialProperty();
        case 'NiNode': return new NiNode();
        case 'AvoidNode': return new AvoidNode();
        case 'NiBSAnimationNode': return new NiBSAnimationNode();
        case 'NiBSParticleNode': return new NiBSParticleNode();
        case 'NiParticleColorModifier': return new NiParticleColorModifier();
        case 'NiParticleGrowFade': return new NiParticleGrowFade();
        case 'NiParticles': return new NiParticles();
        case 'NiAutoNormalParticles': return new NiAutoNormalParticles();
        case 'NiEmitterModifier': return new NiEmitterModifier();
        case 'NiParticleSystemController': return new NiParticleSystemController();
        case 'NiPixelFormat': return new NiPixelFormat();
        case 'NiParticleCollider': return new NiParticleCollider();
        case 'NiRotatingParticles': return new NiRotatingParticles();
        case 'NiSkinData': return new NiSkinData();
        case 'NiSkinInstance': return new NiSkinInstance();
        case 'NiSkinPartition': return new NiSkinPartition();
        case 'NiTexture': return new NiTexture();
        case 'NiSourceTexture': return new NiSourceTexture();
        case 'NiStringExtraData': return new NiStringExtraData();
        case 'NiTextKeyExtraData': return new NiTextKeyExtraData();
        case 'NiTextureEffect': return new NiTextureEffect();
        case 'NiTexturingProperty': return new NiTexturingProperty();
        case 'NiTriShape': return new NiTriShape();
        case 'NiTriShapeData': return new NiTriShapeData();
        case 'NiUVController': return new NiUVController();
        case 'NiUVData': return new NiUVData();
        case 'NiVertexColorProperty': return new NiVertexColorProperty();
        case 'NiZBufferProperty': return new NiZBufferProperty();
        case 'RootCollisionNode': return new RootCollisionNode();
        default: throw "whoops";
    }
}
}
